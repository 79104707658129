#input-card {
  font-size: 24px;
  margin-left: 12.5%;
  margin-top: 32px;
  width: 75%;
}

#active-gif {
  align-items: center;
  display: flex;
  height: 85%;
  justify-content: center;
  margin-left: 12.5%;
  margin-top: 32px;
  padding: 16px;
  width: 75%;
}
